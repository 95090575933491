<template>
    <div class="small">
        <p class="lead">Fill out the form to receive all the files</p>
        <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="download()">
            <form class="formDownload">
            
                <div class="form-group">
                    <label for="formName">Full Name</label>
                    <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
                        <input v-model="formName" type="text" class="form-control" id="formName" placeholder="John Doe" required>
                        <span>{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="form-group">
                    <label for="formEmail">Email address</label>
                    <ValidationProvider :rules="{ required: true, email: true }" v-slot="{ errors }">
                        <input v-model="formEmail" type="email" class="form-control" id="formEmail" placeholder="john.doe@company.com">
                        <span>{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="form-group">
                    <label for="formCompany">Company</label>
                    <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
                        <input v-model="formCompany" type="text" class="form-control" id="formCompany" placeholder="Great Company">
                     <span>{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="form-group">
                    <label for="formNumber">Phone Number <em>(optional)</em></label>
                    <input v-model="formNumber" type="text" class="form-control" id="formNumber" placeholder="+39021234567">
                </div>
                <div class="form-check">
                    <ValidationProvider :rules="{ rejectsFalse: true }" v-slot="{ errors }">
                        <input v-model="formCheck" type="checkbox" class="form-check-input" id="exampleCheck1">
                        <span>{{ errors[0] }}</span>
                    </ValidationProvider>
                    <label class="form-check-label" for="exampleCheck1">I declare that I have taken <a class="linkEvidence textPrimary" href="https://www.alfalaval.com/legal/privacy-policy/" target="_blank" rel="noopener">information</a> from Alfa Laval for the processing of personal data</label>
                </div>
                <div class="text-center mt-4 mb-4">
                    <b-button class="btn btn-turquoise" :disabled="invalid" v-show="showButton" @click="download">Download</b-button>
                    <b-spinner variant="primary" v-show="showSpinner"></b-spinner>
                </div>    

            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

extend('email', {
  ...email,
  message: 'Email is not valid'
});

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('rejectsFalse', {
  /* eslint-disable arrow-body-style */
  validate: (value) => { return value === true; },
  message: 'This field must be checked'
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },

  props: ['name', 'file', 'link', 'type', 'parent', 'categoryId', 'elementId', 'size'],

  data: () => ({
    formName: sessionStorage.getItem('formName') ? sessionStorage.getItem('formName') : '',
    formEmail: sessionStorage.getItem('formEmail') ? sessionStorage.getItem('formEmail') : '',
    formCompany: sessionStorage.getItem('formCompany') ? sessionStorage.getItem('formCompany') : '',
    formNumber: sessionStorage.getItem('formNumber') ? sessionStorage.getItem('formNumber') : '',
    formCheck: false,
    showButton: true,
    showSpinner: false
  }),

  computed: {
  },

  methods: {      
    download(){
      this.showSpinner = true;
      this.showButton = false;

      const object = {};
      object.categoryId = this.categoryId;
      object.elementId = this.elementId;
      object.file = this.file;
      object.type = this.type;
      if (object.type === 'prodotto') {
        object.typename = this.parent + ' ' + this.size;
      } else {
        object.typename = this.parent;
      }
      
      object.username = this.formName;
      object.email = this.formEmail;
      object.company = this.formCompany;
      object.phone = this.formNumber;

      sessionStorage.setItem('formName', this.formName);
      sessionStorage.setItem('formEmail', this.formEmail);
      sessionStorage.setItem('formCompany', this.formCompany);
      sessionStorage.setItem('formNumber', this.formNumber);

      this.$http.post('/api/richiesta/save', object)
        .then((result) => {
          if (!this.link){
            this.$http
              .get('/api/sito/cos/get', {
                params: { filename: this.file }
              })
              .then((response) => {         
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.style = 'display: none';
                const blob = new Blob([Buffer.from(response.data.Body)], {
                  type: response.data.ContentType
                });

                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = response.data.Metadata.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                
                this.$emit('close');
              })
              .catch((err) => {
                console.error('Error on request', err);
                this.$emit('close');
              });
          } else {
            window.open(this.file);
            this.$emit('close');
          }
        })
        .catch((err) => {
          console.error('Error on save', err);
          this.$emit('close');
        });
          
    },

  }
};
</script>

<style>

</style>
